footer{
  width:100%;
  position: relative;
  z-index: 9;
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding-right: 185px;
  @media(max-width: 768px){
    padding-right: 0;
  }
}

.ftr-content{
  padding-top: 30px;
  & > p{
    font-size: 18px;
    color: #000;
  }
}
