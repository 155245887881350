
.side-contact-form .bring-me{
  top: 30%;
}

.side-contact-form{
  top: 100px;
  bottom: auto;
  z-index: 99999;
}

.section-limit{
  width:100%;
  max-width:1200px;
  margin: 0 auto;
  padding:0px 15px;
}

.ftr-wrap-links{
  max-width: 200px;
  & > ul{
    padding: 20px 0;
    list-style: none;
    margin: 0;
    & > li{
        margin-bottom: 5px;
      & > a{
        font-size: 16px;
      }
    }
  }
}

.list-layout-tit__title{
  font-size: 36px;
  font-weight: 400;
}
